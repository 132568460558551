import { SelectionChangedEvent } from "ag-grid-community";
import { StandardProposalEntity } from "domain/entity/StandardProposal/StandardProposalEntity";
import { EMPTY_STANDARD_PROPOSAL_SEARCH_CRITERIA } from "domain/entity/StandardProposal/StandardProposalSearchCriteria";
import moment from "moment";
import { INITIAL_STANDARD_PROPOSAL_COL_DEF, transferRowData } from "presentation/constant/StandardProposal/StandardProposalColumnDefinition";
import { StandardProposalConstant } from "presentation/constant/StandardProposal/StandardProposalConstant";
import { useStandardProposalVM } from "presentation/hook/StandardProposal/useStandardProposalVM";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Core } from "veronica-ui-component";
import { GroupButtons, HPHTable, Loader } from "veronica-ui-component/dist/component/core";


const StandardProposalTablePanel:React.FC = () => {
    const [standardProposalState] = useStandardProposalTracked();
    const standardProposalVM = useStandardProposalVM();
    const gridRef: any = useRef(null);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const {searchStatus,standardProposalList,isRejected,isRejectedCount,isPendingApproval,isPendingApprovalCount} = standardProposalState;
    const STANDARD_PROPOSAL_CONSTANT = StandardProposalConstant.Table;
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    useEffect(() => {
        if (onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_STANDARD_PROPOSAL_COL_DEF.slice());
        
        if (!standardProposalState.selectedRows ||
            standardProposalState.selectedRows.length <= 0) {        
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        }
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        standardProposalVM.updateSelectedRows(selectedRows);
      }, [standardProposalVM])

      const handleRowClick = useCallback((entity: StandardProposalEntity) => {
          standardProposalVM.onRowClick(entity);
    }, [standardProposalVM])

      const handleRowDoubleClick = useCallback((entity: StandardProposalEntity) => {
          setIsLoading(true);
          standardProposalVM.onRowDoubleClick(entity);
          standardProposalVM.searchStandardProposalItem(entity).then((data) => {
            setIsLoading(false);
        }).catch(error => {            
            setIsLoading(false);
        }).finally(() => {
            setIsLoading(false);
        })
    }, [standardProposalVM])

    const handleGroupButtonClick = useCallback(async (buttonId: string) => {
        standardProposalVM.onShowLoading();
        if(searchStatus && buttonId === searchStatus){
            let newSearchCriteria = null;
            newSearchCriteria = {...EMPTY_STANDARD_PROPOSAL_SEARCH_CRITERIA} 
            newSearchCriteria = { ...newSearchCriteria, 
                effectiveDateFrom: moment().subtract(2, "years").startOf("year").toDate(),
                effectiveDateTo: moment().endOf('year').endOf('day').toDate(), 
            };
            await standardProposalVM.searchAllStandardProposal();
            await standardProposalVM.searchStandardProposal(newSearchCriteria).then((data) => {            
                standardProposalVM.onHideLoading();
            }).catch(error => {            
                standardProposalVM.onHideLoading();
            }).finally(() => {
                standardProposalVM.onHideLoading();
            })
        }else{
            await standardProposalVM.onGroupButtonClick(buttonId).then((data) => {
                standardProposalVM.onHideLoading();
            }).catch(error => {            
                standardProposalVM.onHideLoading();
            }).finally(() => {
                standardProposalVM.onHideLoading();
            })
        }

    }, [searchStatus, standardProposalVM]);
    
    const template = useMemo(() => {
        return(
        <>
          {/* <Core.CheckButton
            checked={isAll}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleGroupButtonClick(e.target.id) }}
            showBadge={true}
            badgeValue={isAllCount>999?'999+':isAllCount}
            disabled={isAllCount===0}
            size="medium"
            inputId="isAll"
            label={STANDARD_PROPOSAL_CONSTANT.ALL_BUT} 
          /> */}
          <Core.CheckButton
            checked={isRejected}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleGroupButtonClick(e.target.id) }}
            showBadge={true}
            badgeValue={isRejectedCount>999?'999+':isRejectedCount}
            disabled={isRejectedCount===0}
            size="medium"
            inputId="isRejected"
            label={STANDARD_PROPOSAL_CONSTANT.REJECTED_BUT} 
          />
          <Core.CheckButton
            checked={isPendingApproval}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleGroupButtonClick(e.target.id) }}
            showBadge={true}
            badgeValue={isPendingApprovalCount>999?'999+':isPendingApprovalCount}
            disabled={isPendingApprovalCount===0}
            size="medium"
            inputId="isPendingApproval"
            label={STANDARD_PROPOSAL_CONSTANT.PENDING_APPROVAL_BUT} 
          />
      </>
  )},[STANDARD_PROPOSAL_CONSTANT.PENDING_APPROVAL_BUT, STANDARD_PROPOSAL_CONSTANT.REJECTED_BUT, handleGroupButtonClick, isPendingApproval, isPendingApprovalCount, isRejected, isRejectedCount])
  
    const memoStandardProposalTable = useMemo(() => {

        return (
        <HPHTable
            id='standard-proposal-table'
            isNewColumnSetting={true}
            columns={INITIAL_STANDARD_PROPOSAL_COL_DEF.slice()}
            data={transferRowData(standardProposalList??[])}
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            rowSelection={"multiple"}
            isRowHighligted={true}
            onSelectionChanged={handleSelectionChange}
            onRowClick={(e: any) => handleRowClick(e.data)}
            onRowDoubleClick={(e: any, entity: StandardProposalEntity) => handleRowDoubleClick(entity)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
            headerLabel={
                <GroupButtons
                    alignment="single-selection"
                    errorMessage=""
                    label=""
                    children={template}
                    />
            }
        />
        );
    },[ handleRowClick, handleRowDoubleClick, handleSelectionChange, standardProposalList, template])

    return <><TableWrapper>
        {(standardProposalState.isLoading || isLoading) && <Loader Indicator="Spinner" size="Medium" /> }
            {memoStandardProposalTable}
        </TableWrapper>
        </>;
}

export default memo(StandardProposalTablePanel);
