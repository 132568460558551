import { SelectionChangedEvent } from "ag-grid-community";
import { StandardProposalItemEntity } from "domain/entity/StandardProposal/StandardProposalItemEntity";
import { approvalProcessStatusDropdownOption, proposalByDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { StandardProposalConstant, standardProposalRequiredFieldList } from "presentation/constant/StandardProposal/StandardProposalConstant";
import { INITIAL_STANDARD_PROPOSAL_DETAIL_COL_DEF, transferRowData } from "presentation/constant/StandardProposal/StandardProposalDetailColumnDefinition";
import { useStandardProposalVM } from "presentation/hook/StandardProposal/useStandardProposalVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { OverflowMenuButton } from "presentation/view/components/OverflowMenuButton";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FieldType, HPHTable, IconButton, IFieldValue, OverflowingSubMenu } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";


const StandardProposalTariffItemTablePanel: React.FC = () => {
    const STANDARD_PROPOSAL_CONSTANT = StandardProposalConstant.Table;
    const [standardProposalState] = useStandardProposalTracked();
    const standardProposalVM = useStandardProposalVM();
    const { searchStatus,searchCriteria,standardProposalItemList,currentSelectedRow,tariffItemSelectedRows,isEditStdProposal } = standardProposalState;
    const gridRef: any = useRef(null);
    const {isSaveClicked} = standardProposalState.stdProposalState;
    const messageBarVM = useMessageBarVM();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    
    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_STANDARD_PROPOSAL_DETAIL_COL_DEF.slice());
        
        // if (!standardProposalState.tariffItemSelectedRows ||
        //     standardProposalState.tariffItemSelectedRows.length <= 0) {        
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        // }
    })


    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        standardProposalVM.updateSelectedTariffItemRows(selectedRows);
    }, [standardProposalVM])

    const handleAdd = useCallback(() => {
        if("FINALIZED" === currentSelectedRow.status || "PROPOSED" === currentSelectedRow.status){
            messageBarVM.showError("Standard Proposal status is \"PROPOSAL\" or \"FINALIZED\", cannot Add.");
            return;
        }
        standardProposalVM.onTariffItemAddClick();
    }, [currentSelectedRow.status, messageBarVM, standardProposalVM]);

    const handClose = useCallback(() => {
        standardProposalVM.onTariffItemCloseClick(isEditStdProposal);
    }, [standardProposalVM,isEditStdProposal]);

    const handEdit = useCallback(() => {
        if(currentSelectedRow.key === ""){
            messageBarVM.showError("Please select a record.");
            return;
        }
        if("FINALIZED" === currentSelectedRow.status || "PROPOSED" === currentSelectedRow.status){
            messageBarVM.showError("Standard Proposal status is \"PROPOSAL\" or \"FINALIZED\", cannot be edit.");
            return;
        }
        standardProposalVM.onTariffItemEidtClick();
    }, [currentSelectedRow.key, currentSelectedRow.status, standardProposalVM, messageBarVM]);

    // const memoStandardProposalHeaderTable = useMemo(() => {
    //     return <StandardProposalHeaderBar/>
    // },[])

    const handleRowDoubleClick = useCallback((entity: StandardProposalItemEntity) => {
        standardProposalVM.onTariffItemRowDoubleClick(entity);
    }, [standardProposalVM]);

    const handDelete = useCallback(() => {
        if("FINALIZED" === currentSelectedRow.status || "PROPOSED" === currentSelectedRow.status){
            messageBarVM.showError("Standard Proposal status is \"PROPOSAL\" or \"FINALIZED\", cannot be delete.");
            return;
        }
        if(tariffItemSelectedRows.length === 0){
            messageBarVM.showError("Please select a record.");
            return;
        }
        standardProposalVM.onTariffItemDeleteClick(tariffItemSelectedRows);
    }, [currentSelectedRow.status, messageBarVM, standardProposalVM, tariffItemSelectedRows]);

    const recordValidate = useCallback(() =>{
        if(!standardProposalState.currentSelectedRow.effectiveDate){
            messageBarVM.showError("Effective Date is mandatory.");
            return false;
        }
        if(!standardProposalState.currentSelectedRow.expiryDate){
            messageBarVM.showError("Expiry Date is mandatory.");
            return false;
            
        }
        if(new Date(standardProposalState.currentSelectedRow.expiryDate) <= new Date(standardProposalState.currentSelectedRow.effectiveDate)){
            messageBarVM.showError("The Expiry Date must be greater than the Effective Date.");
            return false;
        }
        if(!standardProposalState.currentSelectedRow.currencyCode){
            messageBarVM.showError("Currency is mandatory.");
            return false;
        }
        return true;
    },[messageBarVM, standardProposalState.currentSelectedRow.currencyCode, standardProposalState.currentSelectedRow.effectiveDate, standardProposalState.currentSelectedRow.expiryDate])


    const handleSave = useCallback(() => {
        if(isEditStdProposal){
            if(recordValidate()){
                standardProposalVM.onShowLoading();
                standardProposalVM.onSaveStdProposal(standardProposalState.currentSelectedRow).then(async (data) => {
                    if(data.code !== "200"){
                        messageBarVM.showError(data.data);
                        standardProposalVM.onHideLoading();
                        return;
                    }else{
                        if(searchStatus !== ""){
                            await standardProposalVM.onGroupButtonClick(searchStatus);
                        }else{
                            await standardProposalVM.searchStandardProposal(searchCriteria);
                        }
                    }
                    standardProposalVM.onHideLoading();
                });
            }else{
                return;
            }
        }
    }, [isEditStdProposal, messageBarVM, recordValidate, searchCriteria, searchStatus, standardProposalState.currentSelectedRow, standardProposalVM]);
    
    // const isDisable = useCallback(() => {
    //     if(isAddTier||isEditTier||isAddIs||isEditIs||isEditStdProposal) return true
    //     return false;
    // },[isAddIs, isAddTier, isEditIs, isEditStdProposal, isEditTier])

    const memoBtns = useMemo(()=>{

        const hdrBtns = [];
            hdrBtns.push({                
                id: 'onDeleteButton',
                icon: 'Icon-trash',
                title: 'Delete'
            })

        return hdrBtns
    },[]) 

    const handleCopyToNewProposalClick = useCallback(() => {
        if(!("FINALIZED" === currentSelectedRow.status && currentSelectedRow.confirmedDate)){
            messageBarVM.showError("Only finalized proposal can be copy.");
            return;
        }
        if(tariffItemSelectedRows.length === 0){
            messageBarVM.showError("Please select a record.");
            return;
        }
        standardProposalVM.onTariffItemCopyClick(true);
    }, [currentSelectedRow.confirmedDate, currentSelectedRow.status, messageBarVM, standardProposalVM, tariffItemSelectedRows.length]);

    const handleCopyToCombineProposalClick = useCallback(() => {
        if(!("FINALIZED" === currentSelectedRow.status && currentSelectedRow.confirmedDate)){
            messageBarVM.showError("Only finalized proposal can be copy.");
            return;
        }
        if(tariffItemSelectedRows.length === 0){
            messageBarVM.showError("Please select a record.");
            return;
        }
        standardProposalVM.onTariffItemCopyClick(false);
    }, [currentSelectedRow.confirmedDate, currentSelectedRow.status, messageBarVM, standardProposalVM, tariffItemSelectedRows.length]);

    
    const memoCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"185px", marginBottom:"24px",marginLeft: "15px"}}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow?.companyCode || ''}
                fieldValue={currentSelectedRow?.companyCode}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.COMPANY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'companyCode'}
                maxLength={60}
                options={standardProposalState.dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={standardProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    standardProposalVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.COMPANY_CODE, currentSelectedRow?.companyCode, isSaveClicked, standardProposalState.dynamicOptions.companyCodeDropdownOptions, standardProposalVM])

    const memoOperatingTml = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"185px", marginBottom:"24px",marginLeft: "15px"}}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!isEditStdProposal}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow?.operatingTml || ''}
                fieldValue={currentSelectedRow?.operatingTml}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.OPERATING_TML}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'operatingTml'}
                maxLength={60}
                options={standardProposalState.dynamicOptions.operatingTmlDropdownOptions}
                requiredFieldList={standardProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    standardProposalVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.OPERATING_TML, currentSelectedRow?.operatingTml, isEditStdProposal, isSaveClicked, standardProposalState.dynamicOptions.operatingTmlDropdownOptions, standardProposalVM])

    const memoEffectiveDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px",marginLeft: "15px" }}>
            <DatePickerComponent
                    disabled={!isEditStdProposal}
                    label={STANDARD_PROPOSAL_CONSTANT.EFFECTIVE_DATE}
                    width="185px"
                    date={currentSelectedRow?.effectiveDate}
                    fieldName="effectiveDate"
                    errorMessage={""}
                    onDateChange={standardProposalVM.onHeaderFieldChange}/>
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.EFFECTIVE_DATE, currentSelectedRow?.effectiveDate, isEditStdProposal, standardProposalVM.onHeaderFieldChange])
    
        const memoExpiryDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px",marginLeft: "15px" }}>
            <DatePickerComponent
                    disabled={!isEditStdProposal}
                    label={STANDARD_PROPOSAL_CONSTANT.EXPIRY_DATE}
                    width="185px"
                    date={currentSelectedRow?.expiryDate}
                    fieldName="expiryDate"
                    errorMessage={""}
                    onDateChange={standardProposalVM.onHeaderFieldChange}/>
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.EXPIRY_DATE, currentSelectedRow?.expiryDate, isEditStdProposal, standardProposalVM.onHeaderFieldChange])

    const memoCurrency = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"185px", marginBottom: "24px",marginLeft: "15px"}}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!isEditStdProposal}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow?.currencyCode || ''}
                fieldValue={currentSelectedRow?.currencyCode}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.CURRENCY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'currencyCode'}
                maxLength={60}
                options={standardProposalState.dynamicOptions.currencyCodeDropdownOptions}
                requiredFieldList={standardProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    standardProposalVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.CURRENCY, currentSelectedRow?.currencyCode, isEditStdProposal, isSaveClicked, standardProposalState.dynamicOptions.currencyCodeDropdownOptions, standardProposalVM])

    const memoProposalBy = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"185px", marginBottom:"24px",marginLeft: "15px"}}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow?.proposalCreatedByMethod || ''}
                fieldValue={currentSelectedRow?.proposalCreatedByMethod}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.PROPOSAL_BY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'proposalCreatedByMethod'}
                maxLength={60}
                options={proposalByDropdownOption}
                requiredFieldList={standardProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    standardProposalVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.PROPOSAL_BY, currentSelectedRow?.proposalCreatedByMethod, isSaveClicked, standardProposalVM])

    const memoStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"185px", marginBottom:"24px",marginLeft: "15px"}}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow?.status || ''}
                fieldValue={currentSelectedRow?.status}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.STATUS}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'status'}
                maxLength={60}
                options={approvalProcessStatusDropdownOption}
                requiredFieldList={standardProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    standardProposalVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.STATUS, currentSelectedRow?.status, isSaveClicked, standardProposalVM])

    const memoProposalNo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px",marginLeft: "15px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow?.proposalNo || ''}
                fieldValue={currentSelectedRow?.proposalNo}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.PROPOSAL_NO}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'proposalNo'}
                maxLength={60}
                requiredFieldList={standardProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.PROPOSAL_NO, currentSelectedRow?.proposalNo, standardProposalVM])

    const memoNature = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px",marginLeft: "15px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!isEditStdProposal}
                isShowOptional={false}
                // readOnlyValue={currentSelectedRow?.tariffNature || ''}
                readOnlyValue={currentSelectedRow?.tariffNature && currentSelectedRow?.tariffNature?.length <=20 ? currentSelectedRow?.tariffNature : (currentSelectedRow?.tariffNature?.substring(0, 20) + '...') || ''}
                fieldValue={currentSelectedRow?.tariffNatureList}
                allValues={currentSelectedRow?.tariffNature??""}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.TARIFF_NATURE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffNatureList'}
                maxLength={600}
                isMultipleDropDown={true}
                options={standardProposalState.dynamicOptions.tariffNatureDropdownOptions}
                requiredFieldList={standardProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue, fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.TARIFF_NATURE, currentSelectedRow?.tariffNature, currentSelectedRow?.tariffNatureList, isEditStdProposal, standardProposalState.dynamicOptions.tariffNatureDropdownOptions, standardProposalVM])

    const memoRejectReason = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px",marginLeft: "15px" }} >
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow?.rejectReason || ''}
                fieldValue={currentSelectedRow?.rejectReason}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.REJECT_REASON}
                isSaveClicked={false}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'rejectReason'}
                maxLength={200}
                requiredFieldList={standardProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onHeaderTextAreaChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)}
            />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.REJECT_REASON, currentSelectedRow?.rejectReason, standardProposalVM])

    const memoRemarks = useMemo(() =>
        <div className='im-flex-row-item' id="std-proposal-edit-remarks"  style={{ width: "405px", marginBottom: "24px",marginLeft: "15px" }} >
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!isEditStdProposal}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow?.remarks || ''}
                fieldValue={currentSelectedRow?.remarks}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.REMARKS}
                isSaveClicked={false}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'remarks'}
                maxLength={200}
                rows={1}
                requiredFieldList={standardProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onHeaderTextAreaChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)}
            />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.REMARKS, currentSelectedRow?.remarks, isEditStdProposal, standardProposalVM])

        
    const memoStandardProposalTariffItemTable = useMemo(() => {
        const copyItemList: OverflowingSubMenu[] = [{
                title: 'New',
                command:handleCopyToNewProposalClick
            },
            {
                title: 'Combine',
                command:handleCopyToCombineProposalClick
            }
        ];

        return (
            <HPHTable
                id='standard-proposal-detail-table'
                isNewColumnSetting={true}
                columns={INITIAL_STANDARD_PROPOSAL_DETAIL_COL_DEF.slice()}
                data={transferRowData(standardProposalItemList ?? [])}
                headerActionButtons={memoBtns}
                onDeleteButton={handDelete}
                headerLabel={<OverflowMenuButton disabled={false} icon="Icon-copy" toolTipText="Copy" navigationList={copyItemList} className={"title-overflowMenu-forStdProposalCopy"} />}
                showPaginator={false}
                editable={false}
                showAddIcon={true}
                onAddClick={handleAdd}
                showDeleteButton={false}
                showUploadIcon={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                rowSelection={"multiple"}
                isRowHighligted={true}
                onRowDoubleClick={(e: any, entity: StandardProposalItemEntity) => handleRowDoubleClick(entity)}
                onSelectionChanged={handleSelectionChange}
                gridHeight="customHeight"
                customHeight="calc(100vh - 340px)"
                ref={gridRef}
            />
        );
    }, [handDelete,handleAdd,handleCopyToCombineProposalClick,handleRowDoubleClick,handleSelectionChange,handleCopyToNewProposalClick,memoBtns,standardProposalItemList])

    return <><div className="main-comp-wrapper">

        <TableWrapper>
        <div className={'flex-row'}>
            <div className={'flex-row-item flex-row-item-full-width'}>
            <Sidebarheader>
            <SidebarTitle></SidebarTitle>
                <SidebarActionCross>
                    {!isEditStdProposal&&<IconButton
                        fileName="Icon-pen" size="medium" toolTipText='Edit' toolTipArrow={false} onClick={handEdit} />}
                    <IconButton
                        fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={handClose} />
                    {isEditStdProposal&&<IconButton
                        fileName="Icon-tick" size="medium" toolTipText='Save' toolTipArrow={false} onClick={handleSave} />}
                </SidebarActionCross>
            </Sidebarheader>
            </div>
        </div>
        <Sidebarheader style={{width: '100%'}}>
        <CriteriaItemContainer>
            {memoCompanyCode}
            {memoProposalNo}
            {memoStatus}
            {memoProposalBy}
            {memoEffectiveDate}
            {memoExpiryDate}
            {memoOperatingTml}
            {memoCurrency}
            {memoNature}
            {memoRejectReason}
            {memoRemarks}
        </CriteriaItemContainer>
    </Sidebarheader>
        {memoStandardProposalTariffItemTable}
    </TableWrapper>
    </div></>;
}

export default memo(StandardProposalTariffItemTablePanel);
