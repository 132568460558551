import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { StandardProposalConstant } from "./StandardProposalConstant";

const STANDARD_PROPOSAL_CONSTANT = StandardProposalConstant.Table;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_STD_PROPOSAL_COPY_TO_COL_DEF: any[] = [
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.TARIFF_TYPE,
        field: 'tariffType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        // pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        filter: false,
        width: 160,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.CHARGE_PERCENTAGE,
        field: 'chargePercentage',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.ROUND_METHOD,
        field: 'roundMethod',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.DECIMAL_PLACE,
        field: 'decimalPlace',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },

].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}
