import { SelectionChangedEvent } from "ag-grid-community";
import { StdProposalCopyToEntity } from "domain/entity/StandardProposal/StdProposalCopyToEntity";
import { StdProposalCopyToNewProposalEntity } from "domain/entity/StandardProposal/StdProposalCopyToNewProposalEntity";
import { StdProposalCopyToProposalEntity } from "domain/entity/StandardProposal/StdProposalCopyToProposalEntity";
import { StandardProposalConstant } from "presentation/constant/StandardProposal/StandardProposalConstant";
import { INITIAL_STD_PROPOSAL_COPY_TO_COL_DEF } from "presentation/constant/StandardProposal/StdProposalCopyToColumnDefinition";
import { INITIAL_STD_PROPOSAL_COPY_TO_NEW_PROPOSAL_COL_DEF } from "presentation/constant/StandardProposal/StdProposalCopyToNewProposalColumnDefinition";
import { INITIAL_STD_PROPOSAL_COPY_TO_PROPOSAL_COL_DEF } from "presentation/constant/StandardProposal/StdProposalCopyToProposalColumnDefinition";
import { useStandardProposalVM } from "presentation/hook/StandardProposal/useStandardProposalVM";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo } from "react";
import { FieldType, HPHTable, IconButton, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import UpdatePercentageAndProposalNoPanel from "./UpdatePercentageAndProposalNoPanel";

const CopyStdProposalTablePanel:React.FC = () => {
    
    const STANDARD_PROPOSAL_CONSTANT = StandardProposalConstant.Table;
    const [standardProposalState] = useStandardProposalTracked();
    const standardProposalVM = useStandardProposalVM();
    const {copyToNewProposalList,isCopyToNewProposal,isEditPercentage,isEditProposalNo,isEditNewProposalNo,copyToPercentageList,copyToCompanyList,copyToProposalList} = standardProposalState;
    
    const handClose = useCallback(() => {
        standardProposalVM.onTariffItemCopyCloseClick();
    }, [standardProposalVM]);
    /*
    const memoCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"120px", marginBottom:"8px",marginLeft: "10px"}}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow.companyCode || ''}
                fieldValue={copyToCompanyList}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.COMPANY_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'companyCode'}
                maxLength={60}
                options={standardProposalState.dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    standardProposalVM.onCopyFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.COMPANY_CODE, copyToCompanyList, currentSelectedRow.companyCode, standardProposalState.dynamicOptions.companyCodeDropdownOptions, standardProposalVM])
    
    const memoProposalNo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "120px", marginBottom:"8px",marginLeft: "10px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentSelectedRow?.proposalNo || ''}
                fieldValue={currentSelectedRow?.proposalNo}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.PROPOSAL_NO}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'proposalNo'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.PROPOSAL_NO, currentSelectedRow?.proposalNo, standardProposalVM])

        const memoOperatingTml = useMemo(() =>
            <div className='im-flex-row-item' style={{width:"120px", marginBottom:"8px",marginLeft: "10px"}}>
                <NbisCommonField
                    errorMessages={{}}
                    isReadOnly={true}
                    isShowOptional={false}
                    readOnlyValue={currentSelectedRow?.operatingTml || ''}
                    fieldValue={currentSelectedRow?.operatingTml}
                    fieldLabel={STANDARD_PROPOSAL_CONSTANT.OPERATING_TML}
                    isSaveClicked={false}
                    fieldType={FieldType.DROPDOWN}
                    fieldKey={'operatingTml'}
                    maxLength={60}
                    options={standardProposalState.dynamicOptions.operatingTmlDropdownOptions}
                    requiredFieldList={[]}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        standardProposalVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
            </div>
            , [STANDARD_PROPOSAL_CONSTANT.OPERATING_TML, currentSelectedRow?.operatingTml, standardProposalState.dynamicOptions.operatingTmlDropdownOptions, standardProposalVM])

            const memoEffectiveDate = useMemo(() =>
                <div className='im-flex-row-item' style={{ width: "120px", marginBottom:"8px",marginLeft: "10px" }}>
                    <DatePickerComponent
                            disabled={true}
                            label={STANDARD_PROPOSAL_CONSTANT.EFFECTIVE_DATE}
                            width="185px"
                            date={currentSelectedRow?.effectiveDate}
                            fieldName="effectiveDate"
                            errorMessage={""}
                            onDateChange={standardProposalVM.onHeaderFieldChange}/>
                </div>
                , [STANDARD_PROPOSAL_CONSTANT.EFFECTIVE_DATE, currentSelectedRow?.effectiveDate, standardProposalVM.onHeaderFieldChange])
            */
    const memoCompanyCodeList = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"650px", marginBottom:"20px",marginLeft: "10px"}}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={''}
                fieldValue={copyToCompanyList}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.COMPANY_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'copyToCompanyList'}
                maxLength={60}
                isMultipleDropDown={true}
                options={standardProposalState.dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    standardProposalVM.onCopyToCompanyFieldChange(isCopyToNewProposal,fieldKey, fieldValue && fieldValue, fFullValue)} />
        </div>
        , [isCopyToNewProposal,STANDARD_PROPOSAL_CONSTANT.COMPANY_CODE, copyToCompanyList, standardProposalState.dynamicOptions.companyCodeDropdownOptions, standardProposalVM])


    const handlePercentageSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        standardProposalVM.updateSelectedPercentageRow(selectedRows);
    }, [standardProposalVM])

    const handleProposalNoSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        standardProposalVM.updateSelectedProposalNoRow(selectedRows);
    }, [standardProposalVM])

    const handlePercentageDoubleClick = useCallback((entity: StdProposalCopyToEntity) => {
        standardProposalVM.onPercentageDoubleClick(entity);
    }, [standardProposalVM])

    const handleProposalNoDoubleClick = useCallback((entity: StdProposalCopyToProposalEntity) => {
        standardProposalVM.onProposalNoDoubleClick(entity);
    }, [standardProposalVM])

    const handleNewProposalNoDoubleClick = useCallback((entity: StdProposalCopyToNewProposalEntity) => {
        standardProposalVM.onNewProposalNoDoubleClick(entity);
    }, [standardProposalVM])

    const handleNewProposalNoSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        standardProposalVM.updateSelectedNewProposalNoRow(selectedRows);
    }, [standardProposalVM])

    const memoStdProposalCopyToTable = useMemo(() => {
        return (
        <HPHTable
        headerLabel={"Tariff List"}
            id='standard-proposal-table'
            isNewColumnSetting={true}
            columns={INITIAL_STD_PROPOSAL_COPY_TO_COL_DEF}
            data={(copyToPercentageList.slice() || [])}
            showPaginator={false}
            editable={false}
            showUploadIcon={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            rowSelection={"single"}
            isRowHighligted={true}
            onRowDoubleClick={(e: any, entity: StdProposalCopyToEntity) => handlePercentageDoubleClick(entity)}
            onSelectionChanged={handlePercentageSelectionChange}
            gridHeight="customHeight"
            customHeight="calc(100vh - 750px)" 
        />
        );
    },[copyToPercentageList, handlePercentageDoubleClick, handlePercentageSelectionChange])

    const memoCopyToProposalTable = useMemo(() => {

        return (
        <HPHTable
            headerLabel={"Proposal List"}
            id='standard-proposal-table'
            isNewColumnSetting={true}
            columns={INITIAL_STD_PROPOSAL_COPY_TO_PROPOSAL_COL_DEF}
            data={(copyToProposalList.slice() || [])}
            showPaginator={false}
            editable={false}
            showUploadIcon={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            rowSelection={"single"}
            isRowHighligted={true}
            onRowDoubleClick={(e: any, entity: StdProposalCopyToProposalEntity) => handleProposalNoDoubleClick(entity)}
            onSelectionChanged={handleProposalNoSelectionChange}
            gridHeight="customHeight"
            customHeight="calc(100vh - 750px)" 
        />
        );
    },[copyToProposalList, handleProposalNoDoubleClick, handleProposalNoSelectionChange])

    const memoCopyToNewProposalTable = useMemo(() => {

        return (
        <HPHTable
            headerLabel={"Proposal List"}
            id='standard-proposal-table'
            isNewColumnSetting={true}
            columns={INITIAL_STD_PROPOSAL_COPY_TO_NEW_PROPOSAL_COL_DEF}
            data={(copyToNewProposalList.slice() || [])}
            showPaginator={false}
            editable={false}
            showUploadIcon={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            rowSelection={"single"}
            isRowHighligted={true}
            onRowDoubleClick={(e: any, entity: StdProposalCopyToNewProposalEntity) => handleNewProposalNoDoubleClick(entity)}
            onSelectionChanged={handleNewProposalNoSelectionChange}
            gridHeight="customHeight"
            customHeight="calc(100vh - 750px)" 
        />
        );
    },[copyToNewProposalList, handleNewProposalNoDoubleClick, handleNewProposalNoSelectionChange])

    const memoUpdatePercentageAndProposalNoPanel = useMemo(() => {
        return <UpdatePercentageAndProposalNoPanel/>
    },[])

    return <>
        <div className={'flex-row'} style={{height:"30px", maxHeight:"30px"}}>
            <div className={'flex-row-item flex-row-item-full-width'}>
                <Sidebarheader>
                    <SidebarTitle style={{marginLeft: "10px"}}>{isCopyToNewProposal?"Copy To":"Combine To"}</SidebarTitle>
                    <SidebarActionCross>
                        <IconButton
                            fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={handClose} />
                    </SidebarActionCross>
                </Sidebarheader>
            </div>
        </div>
        {/* <CriteriaItemContainer>
            {memoCompanyCode}
            {memoOperatingTml}
            {memoProposalNo}
            {memoEffectiveDate}
        </CriteriaItemContainer> */}

        <CriteriaItemContainer>
            {memoCompanyCodeList}
        </CriteriaItemContainer>
        <div className="main-comp-wrapper">
            <div style={{ width: '100%', height: '24%', marginBottom: '24px' }}>
                <TableWrapper> {memoStdProposalCopyToTable} </TableWrapper>
            </div>
            <div style={{ width: '100%', height: '48%', marginBottom: '24px' }}>
                <TableWrapper> {isCopyToNewProposal?memoCopyToNewProposalTable:memoCopyToProposalTable} </TableWrapper>
            </div>
        </div>
        {<div className={`child-div${(isEditPercentage||isEditProposalNo||isEditNewProposalNo)? " child-div-std-copy-item-edit-expanded " : ""}`}>
            {memoUpdatePercentageAndProposalNoPanel}
        </div>}
    </>;
}

export default memo(CopyStdProposalTablePanel);
