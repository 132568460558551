import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { CopyTariffProposalItemCriteria } from "domain/entity/StandardProposal/CopyTariffProposalItemCriteria";
import { SaveTariffProposalItemCriteria } from "domain/entity/StandardProposal/SaveTariffProposalItemCriteria";
import { EMPTY_STANDARD_PROPOSAL_ENTITY, StandardProposalEntity } from "domain/entity/StandardProposal/StandardProposalEntity";
import { EMPTY_STANDARD_PROPOSAL_ITEM_ENTITY, StandardProposalItemEntity } from "domain/entity/StandardProposal/StandardProposalItemEntity";
import { EMPTY_STANDARD_PROPOSAL_SEARCH_CRITERIA, StandardProposalSearchCriteria } from "domain/entity/StandardProposal/StandardProposalSearchCriteria";
import { EMPTY_STD_PROPOSAL_COPY_TO_ENTITY, StdProposalCopyToEntity } from "domain/entity/StandardProposal/StdProposalCopyToEntity";
import { EMPTY_STD_PROPOSAL_COPY_TO_NEW_PROPOSAL_ENTITY, StdProposalCopyToNewProposalEntity } from "domain/entity/StandardProposal/StdProposalCopyToNewProposalEntity";
import { EMPTY_STD_PROPOSAL_COPY_TO_PROPOSAL_ENTITY, StdProposalCopyToProposalEntity } from "domain/entity/StandardProposal/StdProposalCopyToProposalEntity";
import { EMPTY_STD_PROPOSAL_IS_ENTITY, StdProposalIsEntity } from "domain/entity/StandardProposal/StdProposalIsEntity";
import { EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY, StdProposalTariffItemTierEntity } from "domain/entity/StandardProposal/StdProposalTariffItemTierEntity";
import { TariffCodeComboxSearchCriteria } from "domain/entity/StandardProposal/TariffCodeComboxSearchCriteria";
import { ShiftRepository } from "domain/repository/Common/ShiftRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { ExchangeRateRepository } from "domain/repository/ExchangeRate/ExchangeRateRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { StandardProposalItemRepository } from "domain/repository/StandardProposal/StandardProposalItemRepo";
import { StandardProposalRepository } from "domain/repository/StandardProposal/StandardProposalRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import { TariffTypeRepository } from "domain/repository/TariffCode/TariffTypeRepo";
import _ from "lodash";
import { DropdownProps } from "presentation/model/DropdownProps";
import { StandardProposalModel } from "presentation/model/StandardProposal/StandardProposalModel";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface StandardProposalVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<StandardProposalModel>> | ((value: SetStateAction<StandardProposalModel>) => void),
    ],
    companyRepo: CompanyRepository,
    masterDataRepo: MasterDataRepository,
    standardProposalRepo: StandardProposalRepository,
    standardProposalItemRepo: StandardProposalItemRepository,
    exchangeRateRepo: ExchangeRateRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    tariffTypeRepo: TariffTypeRepository,
    shiftRepo: ShiftRepository,
}

export const StandardProposalVM = ({dispatch,companyRepo,masterDataRepo,standardProposalRepo,standardProposalItemRepo,standardTariffCodeRepo
    ,exchangeRateRepo,tariffTypeRepo,shiftRepo
}:StandardProposalVMProps) => {
    const [standardProposalDispatch] = dispatch;
    
    const onShowLoading = () => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const updateSelectedRows = async (rows:any[]) => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                selectedRows: rows,
                currentSelectedRow: rows.length===1?rows[0]:{...EMPTY_STANDARD_PROPOSAL_ENTITY},
                isShowTariffItemPanel: rows.length===0?false:prevState.isShowTariffItemPanel
            }
        })
    }

    const updateCopyToSelectedRows = async (rows:any[]) => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                selectCopyToStdProposalRows: rows,
            }
        })
    }

    const updateSelectedPercentageRow = async (rows:any[]) => {
        if(rows.length === 1){
            standardProposalDispatch(prevState => {
                return {
                    ...prevState,
                    copyToPercentage: rows[0],
                }
            })

        } else{
            standardProposalDispatch(prevState => {
                return {
                    ...prevState,
                    isEditPercentage: false,
                }
            })
        }
    }

    const updateSelectedNewProposalNoRow = async (rows:any[]) => {
        if(rows.length === 1){
            standardProposalDispatch(prevState => {
                return {
                    ...prevState,
                    copyToEditNewProposal: rows[0],
                }
            })
        }else{
            standardProposalDispatch(prevState => {
                return {
                    ...prevState,
                    isEditNewProposalNo: false,
                }
            })
        }
    }

    const updateSelectedProposalNoRow = async (rows:any[]) => {
        if(rows.length === 1){
            standardProposalDispatch(prevState => {
                return {
                    ...prevState,
                    copyToProposalNo: rows[0],
                }
            })
        }else{
            standardProposalDispatch(prevState => {
                return {
                    ...prevState,
                    isEditProposalNo: false,
                }
            })
        }
    }

    const updateSelectedTariffItemRows = async (rows:any[]) => {
        standardProposalDispatch(prevState => {
            let isShow = true;
            if(rows.length === 1){
                // initComboBoxTariffCode(prevState.currentSelectedRow,rows[0].tariffType);
                if(!prevState.isUpdateOrAdd && rows[0] !== prevState.currentTariffItem){
                    isShow = false;
                }
            }
            let copyToEntities: StdProposalCopyToEntity[] = [];
            let items: StandardProposalItemEntity[] = rows.slice();
            let uniqueTariffTypes = new Set<string>();
            if(items.length > 0){
                let uniqueItems = items.filter(item => {
                    if (!uniqueTariffTypes.has(item.tariffType)) {
                        uniqueTariffTypes.add(item.tariffType);
                        return true;
                    }
                    return false;
                });
                copyToEntities = uniqueItems.map((tariff) => ({
                    tariffType: tariff.tariffType,
                    chargePercentage: 100,
                    roundMethod: "ROUND",
                    decimalPlace: "2",
                }));
            }
            return {
                ...prevState,
                copyToPercentageList: copyToEntities,
                tariffItemSelectedRows: rows,
                isEditIs: !isShow?false:prevState.isEditIs,
                isEditTier: !isShow?false:prevState.isEditTier,
                isAddIs: !isShow?false:prevState.isAddIs,
                isAddTier: !isShow?false:prevState.isAddTier,
                isUpdateOrAdd: !isShow?false:prevState.isUpdateOrAdd,
                currentTariffItem: rows.length ===1?rows[0]:{...EMPTY_STANDARD_PROPOSAL_ITEM_ENTITY},
            }
        })
    }

    const updateSelectedTariffItemTierRows = async (rows:any[]) => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                tariffItemTierSelectedRows: rows,
                currentTariffItemTier: rows.length ===1?rows[0]:{...EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY},
            }
        })
    }

    const updateSelectedTariffItemIsRows = async (rows:any[]) => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                tariffItemIsSelectedRows: rows,
                currentTariffItemIs: rows.length ===1?rows[0]:{...EMPTY_STD_PROPOSAL_IS_ENTITY},
            }
        })
    }

    const onRowClick = (entity: StandardProposalEntity) => {
        standardProposalDispatch(prevState => {
            let isShowDtl = false;
            if('' !== prevState.currentSelectedRowKey && entity.key === prevState.currentSelectedRowKey){
                isShowDtl = true;
            }
            return {
                ...prevState,
                isShowTariffItemPanel: isShowDtl?prevState.isShowTariffItemPanel:isShowDtl,
            }
        })
    }

    const onRowDoubleClick = (entity: StandardProposalEntity) => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: false,
                isShowTariffItemPanel: true,
                isUpdateOrAdd: false,
                isAddTariffItem: false,
                isShowTariffItemTierPanel:false,
                standardProposalItemList:[],
                currentSelectedRow: entity,
                rejectReason: entity.rejectReason??"",
                currentSelectedRowKey: entity.key,
                stdProposalState:{
                    ...prevState.stdProposalState,
                    isRead: true,
                }
            }
        })
    }

    const onTariffItemCloseClick = (isEditStdProposal:boolean) => {
        if(isEditStdProposal){
            standardProposalDispatch(prevState => {
                return {
                    ...prevState,
                    isShowTariffItemPanel: true,
                    isEditStdProposal:false,
                }
            })
        }else{
            standardProposalDispatch(prevState => {
                return {
                    ...prevState,
                    isShowTariffItemPanel: false,
                    isShowTariffItemTierPanel: false,
                    isEditStdProposal:false,
                    isUpdateOrAdd:false,
                    isEditTier: false,
                    isEditIs: false,
                    isAddIs: false,
                    isAddTier: false,
                    standardProposalItemList: [],
                    currentSelectedRowKey: '',
                    currentTariffItem:{ ...EMPTY_STANDARD_PROPOSAL_ITEM_ENTITY },
                    stdProposalState:{
                        ...prevState.stdProposalState,
                        isRead: true,
                    }
                }
            })
        }
    }

    const onTariffItemEidtClick = () => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                isEditStdProposal: true,
                isUpdateOrAdd: false,
                isShowTariffItemTierPanel: false,
                isEditTier: false,
                isEditIs: false,
                isAddIs: false,
                isAddTier: false,
                currentSelectedEditRow:prevState.currentSelectedRow,
                // stdProposalState:{
                //     ...prevState.stdProposalState,
                //     isRead: false,
                // }
            }
        })
    }

    const onTariffItemAddClick = () => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                isEditStdProposal: false,
                isAddTariffItem: true,
                isUpdateOrAdd: true,
                isShowTariffItemTierPanel: true,
                currentTariffItem: {...EMPTY_STANDARD_PROPOSAL_ITEM_ENTITY,
                    tempKey: _.uniqueId(),
                },
                // stdProposalState:{
                //     ...prevState.stdProposalState,
                //     isRead: false,
                // }
            }
        })
    }

    const onTariffItemTierCloseClick = () => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                isShowTariffItemTierPanel: false,
                isEditStdProposal: false,
                isAddTariffItem: false,
                isUpdateOrAdd: false,
                isEditTier: false,
                isEditIs: false,
                isAddIs: false,
                isAddTier: false,
                currentTariffItem:{ ...EMPTY_STANDARD_PROPOSAL_ITEM_ENTITY },
            }
        })
    }


    const onTariffItemTierEidtClick = () => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                isEditTier:true,
                isEditIs:false,
                isAddIs:false,
                isAddTier:false,
                isUpdateOrAdd: true,
                currentEditTariffItemTier: prevState.currentTariffItemTier,
            }
        })
    }

    const onTariffItemISEidtClick = () => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                isEditIs:true,
                isEditTier:false,
                isAddIs:false,
                isAddTier:false,
                isUpdateOrAdd: true,
                currentEditTariffItemIs:prevState.currentTariffItemIs,
            }
        })
    }

    const loadDropdownOption = async() => {

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOption = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOption = _.orderBy(companyCodeDropdownOption, "dropdownLabel");

                standardProposalDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        companyCodeDropdownOptions: [
                            ...companyCodeDropdownOption],
                            
                    }
                }))
            }
        );

        await masterDataRepo.getMasterDataByKey(MasterDataType.TERMINAL).then(
            operatingTmls => {
                const operatingTmlDropdownOptions = operatingTmls?.map((operatingTml)=>({
                    dropdownLabel: operatingTml.code,
                    tagLabel: operatingTml.code,
                    value: operatingTml.code,
                })) ?? []

                standardProposalDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        operatingTmlDropdownOptions: operatingTmlDropdownOptions,
                    }
                }))
            }           
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.FORWARDER_CODE).then(
            forwarderCodes => {
                const forwarderCodesDropdownOptions = forwarderCodes?.map((forwarderCode)=>({
                    dropdownLabel: forwarderCode.code,
                    tagLabel: forwarderCode.code,
                    value: forwarderCode.code,
                })) ?? []

                standardProposalDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        forwarderCodeDropdownOptions: forwarderCodesDropdownOptions,
                    }
                }))
            }           
        )

        await exchangeRateRepo.getAllCurrencies().then(
            currencies => {
                let currencyDropdownOptions = currencies?.map((currency) => ({
                    dropdownLabel: currency.currencyCode,
                    tagLabel: currency.currencyCode,
                    value: currency.currencyCode,
                })) ?? []

                standardProposalDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        currencyCodeDropdownOptions: currencyDropdownOptions,
                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.TAR_NATURE).then(
            tariffNatures => {
                let tariffNatureDropdownOptions = tariffNatures?.map((entity) => ({
                    dropdownLabel: entity.code,
                    tagLabel: entity.code,
                    value: entity.code,
                })) ?? []

                standardProposalDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffNatureDropdownOptions: tariffNatureDropdownOptions,
                    }
                }))
            }
        )

        await tariffTypeRepo.getAllActiveTariffTypes().then(
            tariffs => {
                let newTariffs = _.orderBy(tariffs, ["tariffType"]);                
                let tariffTypeDropdownOptions = newTariffs?.map((entity) => ({
                    dropdownLabel: entity.tariffType,
                    tagLabel: entity.tariffType,
                    value: entity.tariffType,
                })) ?? []

                standardProposalDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOptions,
                    }
                }))
            }
        )

        await shiftRepo.getShiftByType('INTENSIVE').then(
            shiftCodes => {

                let newShifts = _.orderBy(shiftCodes, ["shiftCode"]); 
                let shiftCodeDropdownOptions:DropdownProps[] = [];
                newShifts.forEach(shift => {
                    const isShiftCodeExisted = shiftCodeDropdownOptions.find(t => 
                        t.value === shift.shiftCode);
                    if(!isShiftCodeExisted){
                        shiftCodeDropdownOptions.push({
                            dropdownLabel: shift.shiftCode,
                            tagLabel: shift.shiftCode,
                            value: shift.shiftCode,
                        })
                    }
                })   

                standardProposalDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        shiftCodeDropdownOptions: shiftCodeDropdownOptions,
                    }
                }))
            }
        )


    }

    const onSearchCriteriaResetClick = () => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: EMPTY_STANDARD_PROPOSAL_SEARCH_CRITERIA
            }
        })
    }

    const onSearchClick = async() => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: !prevState.isShowCriteriaPanel
            }
        });       
    }


    const searchAllStandardProposal = async() => {
        let newSearchCriteria = {...EMPTY_STANDARD_PROPOSAL_SEARCH_CRITERIA} 
        await standardProposalRepo.searchStandardProposal(newSearchCriteria).then((data) => {
            if(data && data.length>0){
                let isRejectedCount = 0;
                let isPendingApprovalCount = 0;
                // let isAllCount = 0;
                // isAllCount = data.length;
                isRejectedCount = data.filter(entity => entity.status === "REJECTED").length;
                isPendingApprovalCount = data.filter(entity => (entity.status === "FINALIZED") && !entity.confirmedDate ).length;
                const newData = data.map(entity => ({
                    ...entity,
                    tariffNatureList: entity.tariffNature ? entity.tariffNature.split(',') : []
                }));
                const copyToData = data.filter(entity => entity.status === "REJECTED" || entity.status === "NEW");
                standardProposalDispatch(prevState => {
                    return {
                        ...prevState,
                        standardProposalAllList: newData,
                        copyToStandardProposalList: copyToData,
                        // isAllCount: isAllCount,
                        isPendingApprovalCount: isPendingApprovalCount,
                        isRejectedCount: isRejectedCount,
                        selectedRows: [],
                        isAllowAutoSearch:false,
                    }
                })
            }else{
                standardProposalDispatch(prevState => {
                    return {
                        ...prevState,
                        standardProposalAllList: [],
                        isPendingApproval: false,
                        isRejected: false,
                        // isAll: false,
                        isPendingApprovalCount: 0,
                        isRejectedCount: 0,
                        // isAllCount: 0,
                    }
                })
            }
        }).catch((error) => {
            return [];
        })
    }

    const searchStandardProposal = async(searchCriteria: StandardProposalSearchCriteria) => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                selectedRows: [],
                deleteTierList: [],
                deleteIsList: [],
                deleteItemList: [],
                standardProposalList: [],
                isPendingApproval: false,
                isRejected: false,
                isShowTariffItemPanel: false,
                isShowTariffItemTierPanel: false,
                isUpdateOrAdd: false,
                isAddTariffItem: false,
                isShowCopyPanel: false,
                isEditPercentage: false,
                isEditProposalNo: false,
                isEditNewProposalNo: false,
                selectCopyToStdProposalRows:[],
                standardProposalItemList:[],
                currentSelectedRow: {...EMPTY_STANDARD_PROPOSAL_ENTITY},
                isAddIs: false,
                isEditIs: false,
                isAddTier: false,
                isEditTier: false,
                isEditStdProposal: false,
                copyToCompanyList:[],
                copyToPercentageList:[],
                copyToProposalList:[],
                copyToNewProposalList:[],
                copyToEditNewProposal:{...EMPTY_STD_PROPOSAL_COPY_TO_NEW_PROPOSAL_ENTITY},
                copyToEditPercentage:{...EMPTY_STD_PROPOSAL_COPY_TO_ENTITY},
                copyToProposalNo:{...EMPTY_STD_PROPOSAL_COPY_TO_PROPOSAL_ENTITY},
                // isAll: false,
            }
        })
        await searchAllStandardProposal();
        await standardProposalRepo.searchStandardProposal(searchCriteria).then((data) => {
            if(data){
                data = _.orderBy(data, ["companyCode","proposalNo"], ['asc', 'asc']);
                const newData = data.map(entity => ({
                    ...entity,
                    tariffNatureList: entity.tariffNature ? entity.tariffNature.split(',') : []
                }));
                standardProposalDispatch(prevState => {
                    return {
                        ...prevState,
                        standardProposalList: newData,
                        searchCriteria: searchCriteria,
                        selectedRows: [],
                        isAllowAutoSearch:false,
                        searchStatus: ''
                    }
                })
            }else{
                standardProposalDispatch(prevState => {
                    return {
                        ...prevState,
                        standardProposalList: [],
                        searchCriteria: searchCriteria,
                        selectedRows: [],
                        isAllowAutoSearch:false,
                        searchStatus: ''
                    }
                })
            }
        }).catch((error) => {
            return [];
        })

    }

    const onSearchInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
        standardProposalDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e.target.value,
            }
        }))
    };

    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        standardProposalDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
            }
        }));
    };


    const onDateRangeChange = (startDate: any, endDate: any,  dateFields:{startField:string, endField: string}) => {
        standardProposalDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onGroupButtonClick = async (buttonId: string) => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                selectedRows: [],
                deleteTierList: [],
                deleteIsList: [],
                deleteItemList: [],
                standardProposalList: [],
                isShowTariffItemPanel: false,
                isShowTariffItemTierPanel: false,
                isUpdateOrAdd: false,
                isAddTariffItem: false,
                isShowCopyPanel: false,
                isEditPercentage: false,
                isEditProposalNo: false,
                isEditNewProposalNo: false,
                selectCopyToStdProposalRows:[],
                standardProposalItemList:[],
                currentSelectedRow: {...EMPTY_STANDARD_PROPOSAL_ENTITY},
                isAddIs: false,
                isEditIs: false,
                isAddTier: false,
                isEditTier: false,
                isEditStdProposal: false,
                copyToCompanyList:[],
                copyToPercentageList:[],
                copyToProposalList:[],
                copyToNewProposalList:[],
                copyToEditNewProposal:{...EMPTY_STD_PROPOSAL_COPY_TO_NEW_PROPOSAL_ENTITY},
                copyToEditPercentage:{...EMPTY_STD_PROPOSAL_COPY_TO_ENTITY},
                copyToProposalNo:{...EMPTY_STD_PROPOSAL_COPY_TO_PROPOSAL_ENTITY},
            }
        })
        let isPendingApproval = false;
        let isRejected = false;
        // let isAll = false;
        let status = "";
        switch (buttonId) {  
            case 'isPendingApproval':  
                isPendingApproval = true;
                isRejected = false;
                // isAll = false;
                status = "FINALIZED";
                break;  
            case 'isRejected':  
                // isAll = false;
                isPendingApproval = false;
                isRejected = true;
                status = "REJECTED";
                break;  
            // case 'isAll':  
            //     isAll = true;
            //     isPendingApproval = false;
            //     isRejected = false;
            //     status = "ALL";
            //     break;  
            default:  
        }
        await searchAllStandardProposal().then((data) =>{
            standardProposalDispatch(prevState => {
                let newList: StandardProposalEntity[] = [];
                if(status !== ""){
                    if(status === "FINALIZED"){
                        newList = prevState.standardProposalAllList.filter(entity => entity.status === "FINALIZED" && !entity.confirmedDate);
                    }else{
                        newList = prevState.standardProposalAllList.filter(entity => entity.status === status);
                    }
                    newList = _.orderBy(newList, ["companyCode","proposalNo"], ['asc', 'asc']);
                }
                return {
                    ...prevState,
                    selectedRows:[],
                    // isAll: isAll,
                    isPendingApproval: isPendingApproval,
                    isRejected: isRejected,
                    standardProposalList: newList,
                    searchStatus: buttonId,
                    isAllowAutoSearch: false
                }
            })
        })
    }

    const onDeleteClick = async (entities:StandardProposalEntity[]) => { 
        return await standardProposalRepo.deleteSTDProposal(entities.map(entity => entity.key));
    }

    const onSubmit = async (selectedRows:StandardProposalEntity[]) => { 
        return await standardProposalRepo.submitStandardProposal(selectedRows);
    }

    const onUnconfirm = async (selectedRows:StandardProposalEntity[]) => { 
        return await standardProposalRepo.unconfirmStandardProposal(selectedRows);
    }

    const onTextAreaChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        return standardProposalDispatch(prevState => {
          return {
              ...prevState,
              [fieldKey]: val
          }
        });
      }

    const onRejectReasonTextAreaChange = (fieldValue: IFieldValue) => {
        let val: any = fieldValue;
        return standardProposalDispatch(prevState => {
          return {
              ...prevState,
              currentSelectedRow:{
                    ...prevState.currentSelectedRow,
                  rejectReason: val
              }
          }
        });
      }

    const closeRejectReasonModal = () => {
        standardProposalDispatch(prevState => ({
            ...prevState,
            isShowRejectPad: false,
            isRejecting: true,
        }))
    };
    const cancelRejectReasonModal = () => {
        standardProposalDispatch(prevState => ({
            ...prevState,
            isShowRejectPad: false,
            isRejecting: false,
            currentSelectedRow: {
                ...prevState.currentSelectedRow,
                rejectReason: prevState.rejectReason,
            }
        }))
    };

    const onRejectClick = () => {
        standardProposalDispatch(prevState => ({
            ...prevState,
            isShowRejectPad: true,
        }))
    };

    const onReject = async (searchCriteria:StandardProposalSearchCriteria,searchStatus:string,selectedRow:StandardProposalEntity) => {
        closeRejectReasonModal();
        await standardProposalRepo.onRejectStandardProposal(selectedRow).then(async (data) => {
            if(searchStatus !== ""){
                onGroupButtonClick(searchStatus);
            }else{
                await searchStandardProposal(searchCriteria);
            }
            standardProposalDispatch(prevState => ({
                ...prevState,
                isRejecting: false,
                rejectReason: ""
            }))
        });
    };

    const onApprove = async (selectedRows:StandardProposalEntity[]) => {
        return await standardProposalRepo.approveStandardProposal(selectedRows);
    }

    const onAddClick = () => {
        standardProposalDispatch(prevState => ({
            ...prevState,
            isShowDetail: true,
        }))
    };

    const searchStandardProposalItem = async (entity: StandardProposalEntity) => {
        if (!entity.key) {
            return;
        }
        
        // initComboBoxTariffCode(entity,"all");
        await standardProposalItemRepo.searchStandardProposalItem(entity).then((data) => {
            standardProposalDispatch(prevState => {
                return {
                    ...prevState,
                    standardProposalItemList: data,
                    tariffItemSelectedRows: [],
                }
            })
        }).catch((error) => {
            return [];
        })
    }

    const onHeaderSingleDropdownChange = (e: any, fieldName: string) => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: {
                    ...prevState.currentSelectedRow,
                    [fieldName]: e.value,
                }
            }
        });
    };

    const onHeaderMultipleDropdownChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        standardProposalDispatch(prevState => ({
            ...prevState,
            currentSelectedRow: {
                ...prevState.currentSelectedRow,
                [fieldKey]: val,
                // tariffNature: val.join()
            }
        }));
    };

    const onCopyToCompanyCodeProposal = async (copyToCompanyList: string[]) => {
        standardProposalDispatch(prevState => {
            let items: StdProposalCopyToProposalEntity[] = prevState.copyToProposalList.slice();
            const filterCompanyCode = items.filter(item => copyToCompanyList.includes(item.companyCode));
            const updatedCompanyCode = copyToCompanyList.reduce((acc, companyCode) => {
                const existingCode = acc.find(item => item.companyCode === companyCode);
                if (!existingCode) {
                  acc.push({ companyCode, proposalNo: '' }); 
                }
                return acc;
              }, filterCompanyCode);
            return {
                ...prevState,
                copyToProposalList: updatedCompanyCode,
            }
        })
    }

    const onCopyToCompanyFieldChange = async (isCopyToNewProposal: boolean,fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        if(!isCopyToNewProposal){
            standardProposalDispatch(prevState => {
                let updatedCompanyCode: StdProposalCopyToProposalEntity[] = [];
                if(val){
                    let copyToCompanyList = val as string[];
                    let items: StdProposalCopyToProposalEntity[] = prevState.copyToProposalList.slice();
                    const filterCompanyCode = items.filter(item => copyToCompanyList.includes(item.companyCode));
                    updatedCompanyCode = copyToCompanyList.reduce((acc, companyCode) => {
                        const existingCode = acc.find(item => item.companyCode === companyCode);
                        if (!existingCode) {
                        acc.push({ companyCode, proposalNo: '' }); 
                        }
                        return acc;
                    }, filterCompanyCode);
    
                }
                return {
                    ...prevState,
                        [fieldKey]: val,
                        isEditProposalNo: false,
                        copyToProposalList: updatedCompanyCode,
                }
            })
        }else{
            standardProposalDispatch(prevState => {
                let updatedCompanyCode: StdProposalCopyToNewProposalEntity[] = [];
                if(val){
                    let copyToCompanyList = val as string[];
                    let items: StdProposalCopyToNewProposalEntity[] = prevState.copyToNewProposalList.slice();
                    const filterCompanyCode = items.filter(item => copyToCompanyList.includes(item.companyCode));
                    updatedCompanyCode = copyToCompanyList.reduce((acc, companyCode) => {
                        const existingCode = acc.find(item => item.companyCode === companyCode);
                        if (!existingCode) {
                            let newEty = {...EMPTY_STD_PROPOSAL_COPY_TO_NEW_PROPOSAL_ENTITY};
                            newEty = {
                                ...newEty,
                                companyCode:companyCode,
                            }
                        acc.push(newEty); 
                        }
                        return acc;
                    }, filterCompanyCode);
    
                }
                return {
                    ...prevState,
                        [fieldKey]: val,
                        isEditProposalNo: false,
                        copyToNewProposalList: updatedCompanyCode,
                }
            })
        }

        
    }

    const onCopyPercentageFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        standardProposalDispatch(prevState => {
            if(fieldKey==='chargePercentage'){
                var posIntReg = /^\d*(\.\d{0,2})?$/;
                if (!posIntReg.test(val)) { 
                    val = prevState.copyToEditPercentage[fieldKey];
                }
            }
            return {
                ...prevState,
                copyToEditPercentage:{
                    ...prevState.copyToEditPercentage,
                    [fieldKey]: val,
                }
            }
        })
    }
    const onCopyNewProposalNoFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                copyToEditNewProposal:{
                    ...prevState.copyToEditNewProposal,
                    [fieldKey]: val,
                }
            }
        })
    }

    const onCopyProposalNoFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                copyToEditProposalNo:{
                    ...prevState.copyToEditProposalNo,
                    [fieldKey]: val,
                }
            }
        })
    }
    const onCopyFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        standardProposalDispatch(prevState => {
            if((fieldKey==='chargePercentage'||fieldKey==='decimalPlace')){
                var posIntReg = /^\d*(\.\d{0,2})?$/;
                if (!posIntReg.test(val)) { 
                    val = prevState[fieldKey];
                }
            }
            return {
                ...prevState,
                    [fieldKey]: val,
            }
        })
    }

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: {
                    ...prevState.currentSelectedRow,
                    [fieldKey]: val,
                },
            }
        })
    }

    const onTierFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        standardProposalDispatch(prevState => {
            if(prevState.currentEditTariffItemTier && (fieldKey==='cycle'||fieldKey==='forevery')){
                var posIntReg = /^\d*$/;
                if (!posIntReg.test(val)) { 
                    val = prevState.currentEditTariffItemTier[fieldKey];
                }
            }
            if(prevState.currentEditTariffItemTier && (fieldKey==='rate'||fieldKey==='minimum'||fieldKey==='surcharge'||fieldKey==='additionalRate')){
                var numberReg = /^\d*(\.\d{0,2})?$/;
                if (!numberReg.test(val)) { 
                    val = prevState.currentEditTariffItemTier[fieldKey];
                }
            }
            return {
                ...prevState,
                currentEditTariffItemTier: {
                    ...prevState.currentEditTariffItemTier,
                    [fieldKey]: val
                },
            }
        })
    }

    const onIsFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        standardProposalDispatch(prevState => {
            if(prevState.currentEditTariffItemIs && (fieldKey==='percentage')){
                var numberReg = /^\d*(\.\d{0,2})?$/;
                if (!numberReg.test(val)) { 
                    val = prevState.currentEditTariffItemIs[fieldKey];
                }
            }
            return {
                ...prevState,
                currentEditTariffItemIs: {
                    ...prevState.currentEditTariffItemIs,
                    [fieldKey]: val
                },
            }
        })
    }

    const onTariffItemFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        // let tarCode: any;
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        standardProposalDispatch(prevState => {
            // if(fieldKey ==='tariffType'){
            //     tarCode = prevState.dynamicOptions.tariffCodeDropdownOptions[val]?prevState.dynamicOptions.tariffCodeDropdownOptions[val][0].value:"";
            // }
            // if(fieldKey ==='tariffCode'){
            //     tarCode = val;
            // }
            return {
                ...prevState,
                currentTariffItem: {
                    ...prevState.currentTariffItem,
                    [fieldKey]: val,
                    // tariffCode:tarCode && tarCode!==null ? tarCode : prevState.currentTariffItem.tariffCode,
                },
            }
        })
    }

    const onHeaderDateRangeChange = (startDate: any, endDate: any, dateFields: { startField: string, endField: string }) => {
        standardProposalDispatch(prevState => ({
            ...prevState,
            currentSelectedRow: {
                ...prevState.currentSelectedRow,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onHeaderTextAreaChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        return standardProposalDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: {
                    ...prevState.currentSelectedRow,
                    [fieldKey]: val,
                }
            }
        });
    }

    const onTariffItemRowDoubleClick = (entity: StandardProposalItemEntity) => {
        return standardProposalDispatch(prevState => {
            return {
                ...prevState,
                isShowTariffItemTierPanel: true,
                isShowCopyPanel: false,
                copyToCompanyList: [],
                copyToNewProposalList:[],
                copyToPercentageList:[],
                copyToProposalList:[],
                isEditPercentage: false,
                isEditProposalNo: false,
                isEditNewProposalNo: false,
                selectCopyToStdProposalRows:[],
                currentTariffItem: entity,
                isAddTariffItem:false,
                isEditStdProposal: false,
                isUpdateOrAdd:false,
            }
        });
    }

    const cancelAddOrEdit = () => {
        return standardProposalDispatch(prevState => {
            return {
                ...prevState,
                isAddIs: false,
                isEditIs: false,
                isAddTier: false,
                isEditTier: false,
                currentTariffItemTier: prevState.currentTariffItemTier,
                currentTariffItemIs: prevState.currentTariffItemIs
            }
        });
    }

    const onAddIs = () => {
        let newEty = {...EMPTY_STD_PROPOSAL_IS_ENTITY};
        newEty = {
            ...newEty,
            tempKey:_.uniqueId()
        }
        return standardProposalDispatch(prevState => {
            return {
                ...prevState,
                isAddIs: true,
                isEditIs: false,
                isAddTier: false,
                isEditTier: false,
                isUpdateOrAdd: true,
                currentEditTariffItemIs: newEty
            }
        });
    }

    const onAddTier = () => {
        return standardProposalDispatch(prevState => {
            return {
                ...prevState,
                isAddIs: false,
                isEditIs: false,
                isAddTier: true,
                isEditTier: false,
                isUpdateOrAdd: true,
                currentEditTariffItemTier: {...EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY}
            }
        });
    }
    
    const onSaveStdProposalClick = async () => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                isEditStdProposal: false,
                currentSelectedRow: prevState.currentSelectedRow,
            };
        })
    }

    const onSaveStdProposal = async (entity: StandardProposalEntity) => {
        return await standardProposalRepo.saveStandardProposal(entity);
    }

    const onSaveTariffItem = async (entity: StandardProposalEntity,currentItem: StandardProposalItemEntity,standardProposalItemList:StandardProposalItemEntity[],tariffCodeIds:string[],
        deleteItemList: StandardProposalItemEntity[]) => {
            let newList = standardProposalItemList.slice();
            if(!currentItem.key && currentItem.tariffType && currentItem.tariffCode){
                newList.push(currentItem);
            }else{
                newList = standardProposalItemList.map(entity => entity.key===currentItem.key? currentItem:entity);
            }
        let criteria:SaveTariffProposalItemCriteria = {
            tariffProposal:entity,
            // proposalItem:tariffItem,
            tariffCodeIds:tariffCodeIds,
            itemList:newList,
            deleteItemList:deleteItemList,
        }
        return await standardProposalItemRepo.saveTariffItem(criteria);
    }

    const onTempSaveTariffItemTier = async (isAddTier:boolean) => {
        standardProposalDispatch(prevState => {
            let newTiers: StdProposalTariffItemTierEntity[] = [];
            let tier = prevState.currentEditTariffItemTier;
            if(prevState.currentTariffItem.tierList){
                newTiers = prevState.currentTariffItem.tierList.slice();
                if(isAddTier){
                    tier = {
                        ...tier,
                        tierNo: newTiers.length +1,
                        newRecord: true,
                    }
                    newTiers.push(tier);
                }else{
                    newTiers = newTiers.map(entity => entity.tierNo === tier.tierNo ? tier : entity);
                }
            }else{
                tier = {
                    ...tier,
                    tierNo: newTiers.length +1,
                    newRecord: true,
                }
                newTiers.push(tier);
            }
            return {
                ...prevState,
                isEditTier: false,
                isAddTier: false,
                currentTariffItemTier: tier,
                currentEditTariffItemTier: {...EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY},
                currentTariffItem:{
                    ...prevState.currentTariffItem,
                    tierList: newTiers,
                }

            }
        })
    }

    const onTempSaveTariffItemIs = async (isAddIs:boolean) => {
        standardProposalDispatch(prevState => {
            let newIsList: StdProposalIsEntity[] = [];
            let is = prevState.currentEditTariffItemIs;
            if(prevState.currentTariffItem.stdIsList){
                newIsList = prevState.currentTariffItem.stdIsList.slice();
                if(isAddIs){
                    newIsList.push(is);
                }else{
                    newIsList = newIsList.map(entity => entity.id? (entity.id === is.id ? is : entity):(entity.tempKey === is.tempKey ? is : entity));
                }
            }else{
                newIsList.push(is);
            }
            return {
                ...prevState,
                isEditIs: false,
                isAddIs: false,
                currentTariffItemIs: is,
                currentEditTariffItemIs: {...EMPTY_STD_PROPOSAL_IS_ENTITY},
                currentTariffItem:{
                    ...prevState.currentTariffItem,
                    stdIsList: newIsList,
                }

            }
        })
    }

    const initComboBoxTariffCode = async (entity: StandardProposalEntity,tariffType: string) => {
        standardProposalDispatch(prevState => ({
            ...prevState,
            currentTariffItem: {
                ...prevState.currentTariffItem,
                tariffCode: prevState.isAddTariffItem?"":prevState.currentTariffItem.tariffCode,
            },
            dynamicOptions: {
                ...prevState.dynamicOptions,
                tariffCodeDropdownOptions: [],
            }
        }))
        const criteria:TariffCodeComboxSearchCriteria= {
            stdProposal: entity,
            tariffType: tariffType??"all",
        }
        const tariffCodes = await standardTariffCodeRepo.getTariffCodeForStdProposal(criteria);
        let tariffCodeDropdownOptions = tariffCodes?.map((tariffCode) => ({
            dropdownLabel: tariffCode.split(':')[1],
            tagLabel: tariffCode.split(':')[1],
            value: tariffCode.split(':')[1],
        })) ?? []
        standardProposalDispatch(prevState => ({
            ...prevState,
            tariffCodeIds: tariffCodes,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                tariffCodeDropdownOptions: tariffCodeDropdownOptions,
            }
        }))
    }

    const onRenew = async (keys: number[]) => {
        return await standardProposalRepo.renewStandardProposal(keys);
    }

    const onTariffItemDeleteClick = async (deleteItems: StandardProposalItemEntity[]) => {
        standardProposalDispatch(prevState => {
            let deleteList = prevState.deleteItemList.slice();
            deleteList = [...deleteList,...deleteItems];
            let itemList = prevState.standardProposalItemList??[].slice();
            const newList = itemList.filter(item => 
                !deleteItems.some(delItem => item.tariffType === delItem.tariffType && item.tariffCode === delItem.tariffCode && item.forwarderCode === delItem.forwarderCode)
            );
            return {
                ...prevState,
                deleteItemList: deleteList,
                isUpdateOrAdd: true,
                isShowTariffItemTierPanel: false,
                standardProposalItemList:newList,
                currentTariffItem: {...EMPTY_STANDARD_PROPOSAL_ITEM_ENTITY},
                tariffItemSelectedRows: [],
            }
        })
    }

    const onTariffItemTierDeleteClick = async (deleteTiers: StdProposalTariffItemTierEntity[]) => {
        standardProposalDispatch(prevState => {
            let tierList = prevState.currentTariffItem.tierList??[].slice();
            const newList = tierList.filter(tier => 
                !deleteTiers.some(delTier => tier.tierNo === delTier.tierNo)
            ).map((tier,i) => ({...tier,tierNo:i+1}));
            return {
                ...prevState,
                isUpdateOrAdd: true,
                currentTariffItem:{
                    ...prevState.currentTariffItem,
                    tierList:newList,
                },

            }
        })
    }

    const onTariffItemIsDeleteClick = async (deleteIs: StdProposalIsEntity[]) => {
        standardProposalDispatch(prevState => {
            let isList = prevState.currentTariffItem.stdIsList??[].slice();
            const newList = isList.filter(is => 
                !deleteIs.some(delIs => is.shiftCode === delIs.shiftCode)
            );
            return {
                ...prevState,
                isUpdateOrAdd: true,
                currentTariffItem:{
                    ...prevState.currentTariffItem,
                    stdIsList:newList,
                },
            }
        })
    }

    const onStdProposalSaveClick = async (stdProposal: StandardProposalEntity) => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow:stdProposal
            }
        })
    }

    const onTariffItemCopyClick = async (isNew: boolean) => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                isCopyToNewProposal: isNew,
                isShowTariffItemTierPanel:true,
                isShowCopyPanel:true,
                isUpdateOrAdd: false,
                isAddIs: false,
                isEditIs: false,
                isAddTier: false,
                isEditTier: false,
            }
        })
    }

    const onTariffItemCopyCloseClick = async () => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                copyToCompanyList: [],
                copyToNewProposalList:[],
                copyToPercentageList:[],
                copyToProposalList:[],
                isEditPercentage: false,
                isEditProposalNo: false,
                isEditNewProposalNo: false,
                isShowCopyPanel:false,
                isShowTariffItemTierPanel:false,
                isCopyToNewProposal:false,
            }
        })
    }

    const onTariffItemCopyEditCloseClick = async () => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                isEditPercentage:false,
                isEditProposalNo:false,
                isEditNewProposalNo:false,
                copyToEditPercentage:{...EMPTY_STD_PROPOSAL_COPY_TO_ENTITY},
                copyToProposalNo:{...EMPTY_STD_PROPOSAL_COPY_TO_PROPOSAL_ENTITY},
            }
        })
    }

    const onCopyEditPercentageSaveClick = async () => {
        standardProposalDispatch(prevState => {
            const data = prevState.copyToPercentageList.slice();
            let edit = prevState.copyToEditPercentage;
            const updatedArr = data.map(item => 
                item.tariffType === edit.tariffType ? edit : item
            );
            return {
                ...prevState,
                copyToPercentageList:updatedArr,
                isEditPercentage:false,
            }
        })
    }

    const onCopyEditProposalNoSaveClick = async () => {
        standardProposalDispatch(prevState => {
            const data = prevState.copyToProposalList.slice();
            let edit = prevState.copyToEditProposalNo;
            const updatedArr = data.map(item => 
                item.companyCode === edit.companyCode ? edit : item
            );
            return {
                ...prevState,
                copyToProposalList:updatedArr,
                isEditProposalNo:false,
            }
        })
    }

    const onCopyEditNewProposalNoSaveClick = async () => {
        standardProposalDispatch(prevState => {
            const data = prevState.copyToNewProposalList.slice();
            let edit = prevState.copyToEditNewProposal;
            const updatedArr = data.map(item => 
                item.companyCode === edit.companyCode ? edit : item
            );
            return {
                ...prevState,
                copyToNewProposalList:updatedArr,
                isEditNewProposalNo:false,
            }
        })
    }

    const onTariffItemNewCopy = async (fromTariffItemList:StandardProposalItemEntity[],copyToPercentageList: StdProposalCopyToEntity[],copyToNewProposalList: StdProposalCopyToNewProposalEntity[],currenctProposal:StandardProposalEntity) => {
        const criteria:CopyTariffProposalItemCriteria= {
            tariffCodeIds: [],
            fromTariffItemList: fromTariffItemList,
            copyToProposalList: [],
            copyToNewProposalList: copyToNewProposalList,
            copyToPercentageList: copyToPercentageList,
            currenctProposal: currenctProposal
        }
        return standardProposalItemRepo.copyStdProposalTariffItems(criteria);
    }

    const onTariffItemCombineCopy = async (fromTariffItemList:StandardProposalItemEntity[],copyToPercentageList: StdProposalCopyToEntity[],copyToProposalList: StdProposalCopyToProposalEntity[],currenctProposal:StandardProposalEntity) => {
        const criteria:CopyTariffProposalItemCriteria= {
            tariffCodeIds: [],
            copyToNewProposalList: [],
            fromTariffItemList: fromTariffItemList,
            copyToProposalList: copyToProposalList,
            copyToPercentageList: copyToPercentageList,
            currenctProposal: currenctProposal
        }
        return standardProposalItemRepo.copyStdProposalTariffItems(criteria);
    }

    const onPercentageDoubleClick = (entity: StdProposalCopyToEntity) => {
        return standardProposalDispatch(prevState => {
            return {
                ...prevState,
                copyToEditPercentage: entity,
                isEditPercentage: true,
                isEditProposalNo: false,
            }
        });
    }

    const onNewProposalNoDoubleClick = async (entity: StdProposalCopyToNewProposalEntity) => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                copyToEditNewProposal: entity,
                isEditProposalNo: false,
                isEditPercentage: false,
                isEditNewProposalNo: true,
            }
        })
    }

    const onProposalNoDoubleClick = async (entity: StdProposalCopyToProposalEntity) => {
        let searchCriteria = {...EMPTY_STANDARD_PROPOSAL_SEARCH_CRITERIA};
        searchCriteria = 
            { ...searchCriteria, 
                companyCodeList: [entity.companyCode],
                statusList: ['REJECTED','NEW'], 
            
        }
        const data = await standardProposalRepo.searchStandardProposal(searchCriteria);
        if(data){
            let companyCodeProposalNoDropdownOption = data?.map((ety)=>({
                dropdownLabel: ety.proposalNo??"",
                tagLabel: ety.proposalNo??"",
                value: ety.proposalNo??"",
            })) ?? []

            standardProposalDispatch(prevState => {
                return {
                    ...prevState,
                    copyToEditProposalNo: entity,
                    isEditProposalNo: true,
                    isEditPercentage: false,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        companyCodeProposalNoDropdownOptions: companyCodeProposalNoDropdownOption,
                    }
                }
            })
        }
    }

    return {
        onTariffItemNewCopy: onTariffItemNewCopy,
        onCopyEditNewProposalNoSaveClick: onCopyEditNewProposalNoSaveClick,
        onCopyNewProposalNoFieldChange: onCopyNewProposalNoFieldChange,
        updateSelectedNewProposalNoRow: updateSelectedNewProposalNoRow,
        onNewProposalNoDoubleClick: onNewProposalNoDoubleClick,
        onTariffItemCombineCopy: onTariffItemCombineCopy,
        onCopyPercentageFieldChange: onCopyPercentageFieldChange,
        onCopyProposalNoFieldChange: onCopyProposalNoFieldChange,
        onTariffItemCopyEditCloseClick: onTariffItemCopyEditCloseClick,
        onCopyEditProposalNoSaveClick: onCopyEditProposalNoSaveClick,
        onCopyEditPercentageSaveClick: onCopyEditPercentageSaveClick,
        onPercentageDoubleClick: onPercentageDoubleClick,
        onProposalNoDoubleClick: onProposalNoDoubleClick,
        updateSelectedProposalNoRow: updateSelectedProposalNoRow,
        updateSelectedPercentageRow: updateSelectedPercentageRow,
        onCopyToCompanyCodeProposal: onCopyToCompanyCodeProposal,
        onCopyToCompanyFieldChange: onCopyToCompanyFieldChange,
        onCopyFieldChange: onCopyFieldChange,
        onTariffItemCopyCloseClick: onTariffItemCopyCloseClick,
        updateCopyToSelectedRows: updateCopyToSelectedRows,
        onTariffItemCopyClick: onTariffItemCopyClick,
        onStdProposalSaveClick: onStdProposalSaveClick,
        onTariffItemIsDeleteClick: onTariffItemIsDeleteClick,
        onTariffItemDeleteClick: onTariffItemDeleteClick,
        onTariffItemTierDeleteClick: onTariffItemTierDeleteClick,
        onHeaderMultipleDropdownChange: onHeaderMultipleDropdownChange,
        onHeaderSingleDropdownChange: onHeaderSingleDropdownChange,
        onHeaderTextAreaChange: onHeaderTextAreaChange,
        onHeaderDateRangeChange: onHeaderDateRangeChange,
        onHeaderFieldChange: onHeaderFieldChange,
        onTierFieldChange: onTierFieldChange,
        onIsFieldChange: onIsFieldChange,
        loadDropdownOption: loadDropdownOption,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onRowDoubleClick: onRowDoubleClick,
        updateSelectedRows: updateSelectedRows,
        onSearchCriteriaResetClick: onSearchCriteriaResetClick,
        onSearchClick: onSearchClick,
        searchStandardProposal: searchStandardProposal,
        searchAllStandardProposal: searchAllStandardProposal,
        onSearchInputTextChange: onSearchInputTextChange,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onDateRangeChange: onDateRangeChange,
        onGroupButtonClick: onGroupButtonClick,
        onDeleteClick:onDeleteClick,
        onSubmit:onSubmit,
        onUnconfirm:onUnconfirm,
        onTextAreaChange:onTextAreaChange,
        closeRejectReasonModal:closeRejectReasonModal,
        cancelRejectReasonModal:cancelRejectReasonModal,
        onRejectClick:onRejectClick,
        onReject:onReject,
        onApprove:onApprove,
        onAddClick:onAddClick,
        searchStandardProposalItem:searchStandardProposalItem,
        onTariffItemCloseClick: onTariffItemCloseClick,
        onTariffItemEidtClick: onTariffItemEidtClick,
        onTariffItemRowDoubleClick: onTariffItemRowDoubleClick,
        onTariffItemTierCloseClick: onTariffItemTierCloseClick,
        onTariffItemTierEidtClick: onTariffItemTierEidtClick,
        onTariffItemISEidtClick: onTariffItemISEidtClick,
        onTariffItemFieldChange: onTariffItemFieldChange,
        updateSelectedTariffItemTierRows: updateSelectedTariffItemTierRows,
        updateSelectedTariffItemIsRows: updateSelectedTariffItemIsRows,
        updateSelectedTariffItemRows: updateSelectedTariffItemRows,
        cancelAddOrEdit: cancelAddOrEdit,
        onAddIs: onAddIs,
        onAddTier: onAddTier,
        onRowClick: onRowClick,
        onSaveStdProposalClick: onSaveStdProposalClick,
        onSaveStdProposal: onSaveStdProposal,
        onTariffItemAddClick: onTariffItemAddClick,
        onRejectReasonTextAreaChange: onRejectReasonTextAreaChange,
        initComboBoxTariffCode:initComboBoxTariffCode,
        onSaveTariffItem:onSaveTariffItem,
        onTempSaveTariffItemTier:onTempSaveTariffItemTier,
        onTempSaveTariffItemIs:onTempSaveTariffItemIs,
        onRenew:onRenew,
    }
}