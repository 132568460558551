import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { StandardProposalConstant } from "./StandardProposalConstant";

const STANDARD_PROPOSAL_CONSTANT = StandardProposalConstant.Table;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_STANDARD_PROPOSAL_COL_DEF: any[] = [
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.COMPANY_CODE,
        field: 'companyCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        // pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        filter: false,
        width: 200,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.PROPOSAL_NO,
        field: 'proposalNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.EFFECTIVE_DATE,
        field: 'effectiveDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: "date"
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.EXPIRY_DATE,
        field: 'expiryDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: "date"
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.OPERATING_TML,
        field: 'operatingTml',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 160,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.CURRENCY,
        field: 'currencyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.PROPOSAL_BY,
        field: 'proposalCreatedByMethod',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.TARIFF_NATURE,
        field: 'tariffNature',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.CONFIRMED_DATE,
        field: 'confirmedDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
        dataType: "date"
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.STATUS,
        field: 'status',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.REMARKS,
        field: 'remarks',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 380,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.REJECT_REASON,
        field: 'rejectReason',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 380,
    },

].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}
